<template>

  <div class="section-content ma-0 pa-0">
    <div class="primary-section-content">
      <div v-if="level === 'Corporate'">
        <admin-page :disablePageInfo="true"></admin-page>
      </div>
      
      <div v-else class="mt-n1 mr-6">
        <status class="mt-3" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <!-- <status class="mt-3" @resetStatus="clearMessage" v-if="successHas($options.name) || showRoleAddUpdateAlert" :message="successGet($options.name) || roleMessage" status="success"></status> -->

        <hb-data-table-header>
          <template v-slot:description>
            Add / manage roles and user permissions.
          </template>
          <template v-slot:actions>
            <hb-btn small color="secondary" @click="showConfig()">Add New Role</hb-btn>
          </template>
        </hb-data-table-header>

        <v-card elevation="1">
          <v-data-table
            :headers="headers"
            :items="roles"
            disable-pagination
            hide-default-footer
            class="hb-data-table hb-data-table-cursor-on"
            @click:row="showConfig"
          >
            <template v-slot:item.name="{ item }">{{item.name}}</template>
            <template v-slot:item.description="{ item }">{{ item.description }}</template>
            <template v-slot:item.actions="{ item }">
              <hb-menu
                options
                align-right
              >
                <v-list>
                  <v-list-item @click="showConfig(item)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteItem(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </hb-menu>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <edit-role v-model="showEdit" v-if="showEdit" :selected="selected" @showSuccess="editRoleSuccessMessage" @close="closeWindow" @refetch="fetchData"></edit-role>

      <hb-modal v-if="showDelete" show-help-link v-model="showDelete" size="medium" title="Delete Role" @close="closeWindow" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            <status @resetStatus="errorClear('roledelete')" v-if="errorHas('roledelete')" :message="errorGet('roledelete')" status="error"></status>
            Are you sure you want to delete the selected role?
            <br /><br />
            This action cannot be undone.
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn :disabled="isLoading('roledelete')" color="destructive" @click="deleteConfirm">Delete Role</hb-btn>
          <span v-show="isLoading('roledelete')" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </template>
      </hb-modal>
      </div>
  </div>
</template>

<script type="text/babel">
    import AdminPage from '../users/Index.vue'
    import Status from '../includes/Messages.vue';
    import Modal from '../assets/Modal.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import EditRole from './EditRole.vue';
    import draggable from 'vuedraggable';
    import api from '../../assets/api.js';
    import { notificationMixin } from "../../mixins/notificationMixin.js";
    import { mapGetters } from 'vuex';

    export default {
        name: "RoleSettings",
        mixins:[notificationMixin],
        data() {
            return {
                list: [],
                roles:[],
                selected: {},
                showEdit: false,
                showDelete: false,
                showRoleAddUpdateAlert : false,
                roleMessage : '',
                headers: [
                    { text: "Role Name", value: "name" },
                    { text: "Description", value: "description" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            }
        },
        props: {
          level : {
            type: String,
            default: ''
          }
        },
        components:{
            Status,
            Modal,
            EditRole,
            draggable,
            Loader,
            AdminPage
        },
        created(){
            this.fetchData();
        },
        filters:{
            addOrdinal(value){
                if(!value) return null;
                var s = ["th","st","nd","rd"];
                var v = value % 100;
                return value + (s[(v-20)%10]||s[v]||s[0]);
            },
        },
        computed:{
          ...mapGetters({
            storeInterPropertyName: 'authenticationStore/getInterPropertyName'
          }),
        },
        methods:{

            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
                this.errorClear('roledelete');
            },
            fetchData(){
                api.get(this, api.ROLES).then(r => {
                    this.roles = r.roles.filter(item => item.name?.toLowerCase().split(" ").join("-") !== this.storeInterPropertyName);
                });
                console.log(this.roles);
            },
            saveNewOrder(){
                var data = {roles: this.roles};
                api.post(this, api.ROLES_SORT, data)
            },
            showConfig(c = {}){
                this.selected = c;
                this.showEdit = true;
            },
            deleteItem(c){
                this.selected = c;
                this.showDelete = true;
            },
            deleteConfirm(){
                api.delete(this, api.ROLES, this.selected.id, 'roledelete').then(r => {
                    this.showDelete = false;
                    this.fetchData();
                    this.selected = {};
                }).catch((err) => {
                  this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
                });

            },
            editRoleSuccessMessage(roleName, edited){
                this.showRoleAddUpdateAlert = true;
                if(edited) {
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: roleName + ' has been successfully updated.' });
                }
                else {
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: roleName + ' has been successfully added.' });
                }
            },
            clearMessage(){
                this.successClear(this.$options.name);
                this.showRoleAddUpdateAlert = false;
            }
        }
    }
</script>

<style scoped>
  .add-role-btn{
    font-size: 15px;
    text-decoration: none;
  }
  .add-role-btn:focus{
    border: none;
  }
  .roles-table {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
    border-radius: 4px;
  }
  .roles-table .table-head{
    background: #FFFFFF;
    /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
    border-bottom: 1px solid #ccc;
  }
  .roles-table .table-head strong{
    color: #474F5A;
  }
  .roles-table .table-row:hover:not(:first-child){
    background: #F9FAFB;
  }
  .roles-table .table-row:first-child {
    cursor: inherit;
  }
  .roles-table .table-row .table-cell{
    border-bottom: 1px solid #dce8ef;
  }
  .role-modal-header{
    font-size: 16px;
    color: #101318;
  }
  .no-border-all{
    border: none;
  }
  .light-text{
    color: #637381;
  }
</style>

<style>
  .subdued {
    color: #a5adb4;
    line-height: 18px;
  }
  .header-sub-heading{
    font-size     : 14px;
    line-height   : 24px;
    letter-spacing: -0.2px;
    color         : #101318;
  }
  .role-main-section-header{
    font-size   : 24px;
    font-style  : normal;
    font-weight : 500;
    color       : #101318;
  }
</style>
