<template>
  <hb-modal v-model="dialog" show-help-link size="large" :title="selected.id ? 'Edit Role' : 'Add New Role'" @close="$emit('close')">
    <template v-slot:content>
      <v-row
        v-if="isLoading($options.name)"
        style="height: 500px;"
        align="center"
        justify="center"
        no-gutters
      >
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>
      <span v-else>
        <status class="mx-3 mt-3" @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <hb-form label="Role Name" required>
          <hb-text-field
            placeholder="Enter a name for this role"
            name="name"
            v-model="role.name"
            v-validate="'required|max:45'"
            :error="errors.collect('name').length > 0"
          > </hb-text-field>

          <hb-checkbox
            v-model="role.is_default"
            label="Make this the default role"
          >
          </hb-checkbox>
        </hb-form>

        <hb-form label="Description">
          <hb-textarea
            name="description"
            :error="errors.collect('description').length > 0"
            v-model="role.description"
            v-validate="'max:1000'"
            placeholder="Enter a description for this role"
          ></hb-textarea>
        </hb-form>

        <div class="hb-cloud-lighter py-0">&nbsp;</div>
        <v-divider></v-divider>
        <div class="px-4 pt-4">
          <p class="toggle-p hb-text-night-light">Please select the permissions that apply to this role.</p>
        </div>
        <v-row class="top-border ma-0 pa-0">
          <v-col cols="12" class="px-4 pt-0 pb-4" style="background: #F9FAFB;">
            <v-expansion-panels multiple>
              <hb-expansion-panel v-for="(c, i) in categories" :key="i">
                <template v-slot:title>
                  <span class="text-capitalize">{{ c }}</span>
                </template>
                <template v-slot:actions>
                  <span class="hb-font-header-3-medium mr-4" v-if="active_permissions_type_count[c]">{{active_permissions_type_count[c].length}} of {{permission_type(c).length}}</span>
                  <span class="hb-font-header-3-medium mr-4" v-else>0 of {{permission_type(c).length}}</span>
                </template>
                <template v-slot:content>
                  <div class="mb-4">
                    <v-checkbox
                      v-for="(p) in permission_type(c)"
                      :key="p.id"
                      :value="p.id"
                      :label="p.name"
                      v-model="active_permissions"
                      @change="setPermissionsCount(p.id, c)"
                      class="hb-table-checkbox ml-1 pt-0 pb-0 mt-4"
                      hide-details>
                    </v-checkbox>
                  </div>
                </template>
              </hb-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </span>
    </template>
    <template v-slot:actions>
        <hb-btn v-if="selected.id" :disabled="isLoading($options.name)" color="primary" @click="save">Update</hb-btn>
        <hb-btn v-else :disabled="isLoading($options.name)" color="primary" @click="save">Add</hb-btn>
    </template>
  </hb-modal>
</template>

  <!--
  <div>

    <div class="form-section pb-0 pt-0">

      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

      <v-row class="top-border">
        <v-col md="3" class="role-label pl-6">
          <div class="pt-2 font-15px">Role Name</div>
        </v-col>
        <v-col md="9" class="pl-6 pr-6 pb-5">
          <div class="form-input-container">
              <v-text-field
                label="Enter a name for this role"
                single-line
                v-model="role.name"
                v-validate="'required|max:45'"
                class="pa-0 mt-0"
              > </v-text-field>
            <span v-show="errors.has('name')" class="status-block error-block field-error">{{ errors.first('name') }}</span>
          </div>

          <div class="form-input-container">
              <v-checkbox
                hide-details
                v-model="role.is_default"
                label="Make this the default role"
                class="mt-0"
              >
              </v-checkbox>
          </div>
        </v-col>
      </v-row>

      <v-row class="role-desc-row top-border">
        <v-col md="3" class="role-label pl-6">
          <div class="pt-2 font-15px">Description</div>
        </v-col>
        <v-col md="9"  class="pl-6 pr-6 pb-5">
          <div class="form-input-container">
              <v-textarea
                label="Enter a description for this role"
                rows="1"
                single-line
                v-model="role.description"
                v-validate="'max:1000'"
                class="pa-0 mt-0"
              ></v-textarea>
            <span v-show="errors.has('name')" class="status-block error-block field-error">{{ errors.first('name') }}</span>
          </div>
        </v-col>
      </v-row>

      <v-row class="top-border">
        <v-col cols="12" class="px-6 py-4">
          <p class="toggle-p ma-0">Please select the permissions that apply to this role.</p>
        </v-col>
      </v-row>

      <v-row class="top-border">
        <v-col cols="12" class="px-6 py-6" style="background: #F9FAFB;">
        <v-expansion-panels multiple>
        <v-expansion-panel class="new-tenant-form-data elevation-0" v-for="(c, i) in categories" :key="i">
          <v-expansion-panel-header class="pl-7">
            <span class="text-capitalize role-label">{{ c }}</span>
            <span class="pr-6 role-label text-end" v-if="active_permissions_type_count[c]">{{active_permissions_type_count[c].length}} of {{permission_type(c).length}}</span>
            <span class="pr-6 role-label text-end" v-else>0 of {{permission_type(c).length}}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-5">
            <v-checkbox
              v-for="(p) in permission_type(c)"
              :key="p.id"
              :value="p.id"
              :label="p.name"
              v-model="active_permissions"
              @change="setPermissionsCount(p.id, c)"
              dense
              class="hb-table-checkbox"
              hide-details>
            </v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </v-col>
      </v-row>

    </div>
    <div class="modal-footer">
      <v-row class="pl-4">
        <v-col v-show="!agree" v-if="selected.id" cols="8" style="background: #FFF9DA; border: 1px solid rgba(255, 214, 0, 0.35); border-radius: 4px;">
          <v-row class="pa-0">
            <v-col cols="3" class="pa-0"><b>Caution:</b> Confirm</v-col>
            <v-col class="pa-0"><a class="close-link" @click="agree = true">Agree</a></v-col>
          </v-row>
        </v-col>
      <v-col class="py-1">
      <a class="mr-2" @click="$emit('close')">Cancel</a>
        <hb-btn v-if="selected.id" :disabled="isLoading($options.name) || !agree" color="primary" @click="save">Update</hb-btn>
        <hb-btn v-else :disabled="isLoading($options.name)" color="primary" @click="save">Add</hb-btn>
        <span v-show="isLoading($options.name)" >
          <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
        </span>
      </v-col>
      </v-row>
      
    </div>
  </div>

-->


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import api from '../../assets/api.js';
    import { mapGetters, mapActions } from 'vuex';

    import { notificationMixin } from "../../mixins/notificationMixin.js";

    export default {
        name: "RoleSettings",
        mixins:[notificationMixin],
        data() {
            return {
                labels: {
                    P1VBqYBwyr: "Amount Per Month",
                    xlMvQGj8zq: "Amount Per Month",
                },
                permissions: [],
                role:{
                    name: '',
                    description: '',
                    is_default: '',
                    Permissions: []
                },
                active_permissions: [],
                active_permissions_type_count: []
            }
        },
        components:{
            Loader,
            Status,
            Dropdown
        },
        props:[
            'selected',
            'value'
        ],
        computed: {
          ...mapGetters({
            merchandiseSettings: 'productStore/merchandiseSettings',
            getSettings: 'authenticationStore/getSettings',
          }),
          categories() {
            let categories = [...new Set(
              this.permissions
                .filter(x => !(this.getSettings.nationalAccountFeature === '0' && x.category === 'National Accounts')) // Remove manage_national_accounts if feature is disabled
                .map(x => (this.merchandiseSettings || x.category != 'merchandise management') ? x.category : false)
            )];
            return categories.filter(Boolean);
          },
          dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
          },
        },
        async created(){
            await this.fetchPermissions();

            if(this.selected.id){
                this.role = {
                    name: this.selected.name,
                    description: this.selected.description,
                    is_default: !!this.selected.is_default,
                    Permissions: []
                };
                this.active_permissions = this.selected.Permissions.map(p => p.id);
                this.setActivePermissionsCount();
            }
        },
        methods:{
            ...mapActions({
              getLoggedInUser: 'authenticationStore/getLoggedInUser'
            }),
            setActivePermissionsCount() {
              this.categories.map(c => {
                this.permission_type(c).map(cp => {
                  this.setDefaultPermissionsCount(cp.id, c);
                });
              });
            },
            setDefaultPermissionsCount(permission, type) {
              if(this.active_permissions_type_count[type]) {
                let found = this.active_permissions.filter(p => p == permission);
                if(found.length) {
                  this.active_permissions_type_count[type].push(permission);
                }
              }
              else {
                let found = this.active_permissions.filter(p => p == permission);
                if(found.length) {
                  this.active_permissions_type_count[type] = [];
                  this.active_permissions_type_count[type].push(permission);
                }
              }
            },
            setPermissionsCount(permission, type) {
              if(this.active_permissions_type_count[type]) {
                let found = this.active_permissions_type_count[type].filter(p => p == permission);
                if(!found.length) {
                  this.active_permissions_type_count[type].push(permission);
                }
                else {
                  this.active_permissions_type_count[type].splice(this.active_permissions_type_count[type].indexOf(permission), 1);
                }
              }
              else {
                this.active_permissions_type_count[type] = [];
                this.active_permissions_type_count[type].push(permission);
              }
            },
            permission_type(type){
                return this.permissions.filter(p => p.category === type)
            },

            async fetchPermissions(){
                let response = await api.get(this, api.SETTINGS + 'permissions');
                this.permissions = response.permissions.map(p => {
                    p.value = null;
                    if(this.selected && this.selected.id) {
                      let existing = this.selected.Permissions.find(rp => rp.id === p.id);
                      p.value = existing ? existing.value : null;
                    }
                    return p;
                });
                this.permissions.sort((a, b) => {
                  return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
              });
            },
            save(){

                this.validate(this).then(async status => {
                    if(!status) return;

                    this.role.Permissions = this.permissions.filter(p => this.active_permissions.indexOf(p.id) >= 0).map(p => {
                        return {
                            id: p.id,
                            value: p.value
                        }
                    });


                    if(this.selected.id){
                        await api.put(this, api.ADMIN + 'roles/' + this.selected.id,  this.role).then(r => {
                            this.$emit('refetch');
                            this.$emit('close');
                            this.$emit('showSuccess', this.role.name, true);
                        }).catch(error => {
                          this.showMessageNotification({ description: error });
                        });
                    } else {
                        await api.post(this, api.ADMIN + 'roles/', this.role).then(r => {
                            this.$emit('refetch');
                            this.$emit('close');
                            this.$emit('showSuccess', this.role.name, false);
                        }).catch(error => {
                          this.showMessageNotification({ description: error });
                        });
                    }
                    this.getLoggedInUser(true);
                })
            },
        }
    }
</script>

<style scoped>
    .v-expansion-panel.new-tenant-form-data {
      margin-bottom: 20px;
    }
    .v-expansion-panel-header.v-expansion-panel-header--active {
    border-bottom: 1px solid #E1E6EA;
}
    .role-desc-row{
      min-height: 144px;
    }
    .role-label{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
      border-top: 1px solid #DFE3E8;
      margin-left: -25px;
      margin-right: -25px;
    }
    .modal-role-btn{
      background: linear-gradient(180deg, #47C0BF -70%, #00848E 126.25%);
      border: 1px solid #00848E;
      box-sizing: border-box;
      box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
      border-radius: 4px;
    }
    .small-input-label{
      font-size: 15px;
      color: #101318;
    }
    .font-15px{
      font-size: 15px;
    }
  .input-error-label {
    color: red;
    font-size: 12px;
  }
</style>

<style>
  .role-switch .v-input__control .v-input--selection-controls__input .theme--light.v-input--switch__track{
    opacity: 0.9;
  }
  .role-switch .v-input__control .v-input--selection-controls__input .theme--light.v-input--switch__thumb{
    color: #fff !important;
  }
  .top-border .theme--light.v-text-field > .v-input__control > .v-input__slot:before,
  .top-border .theme--light.v-text-field > .v-input__control:hover > .v-input__slot:before{
    border-color: #C4CDD5;
  }
</style>
